@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

.title-weather {
  text-align: center;
  line-height: 2rem;
  color: grey;
  font-family: "Montserrat", sans-serif;

  backdrop-filter: blur(10px);
}

.w-title {
  font-weight: 600;
  color: grey;
}
.text-weather {
  text-align: left;
  padding-left: 10px;
  width: 250px;
  flex-wrap: wrap;
}
.inputField {
  border-radius: 5px;
  border: none;
  color: grey;
  margin: 3px;
  margin-left: 0;
  padding: 5px;
  background-color: rgb(9, 10, 15, 0.5);
}
::placeholder {
  color: rgb(91, 88, 88);
}
.weatherWidgetButtons {
  background-color: rgb(9, 10, 15, 0.5);
  color: grey;
  border-radius: 5px;
  border: none;
  margin: 3px;
  padding: 5px;
}
.sun {
  position: absolute;
  top: -17%;
  right: 2%;
}

.error {
  text-align: center;
  margin-top: 2rem;
}
