@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  text-align: center;
}
.projects-main {
  background-color: #2a2f3f;
}
ul {
  margin-left: 10px;
}
